import BigNumber from 'bignumber.js';
import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { BIG_TEN } from './bigNumber';

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getAmount = (decimalAmount: BigNumber, decimals = 18) => {
    return new BigNumber(decimalAmount).times(BIG_TEN.pow(decimals));
};

export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
};

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2, type = 0) => {
    if (number === 0) {
        return '0';
    }
    try {
        let text = '';
        if (type === 1) {
            if (number > 1000000000) {
                text = 'b';
                number = number / 1000000000;
            } else if (number > 1000000) {
                text = 'm';
                number = number / 1000000;
            } else if (number > 1000) {
                text = 'k';
                number = number / 1000;
            }
        }

        if (type === 2) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            };
            if (number > 100000) {
                return formatBigNumber(number);
            } else if (number > 10000) {
                options.maximumFractionDigits = 1;
                options.minimumFractionDigits = 1;
            } else if (number > 1000) {
                options.maximumFractionDigits = 2;
                options.minimumFractionDigits = 2;
            } else if (number > 100) {
                options.maximumFractionDigits = 3;
                options.minimumFractionDigits = 2;
            } else if (number > 10) {
                options.maximumFractionDigits = 4;
                options.minimumFractionDigits = 2;
            } else if (number > 1) {
                options.maximumFractionDigits = 5;
                options.minimumFractionDigits = 2;
            } else if (number > 0.1) {
                options.maximumFractionDigits = 6;
                options.minimumFractionDigits = 2;
            } else if (number > 0.01) {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            } else if (number < 0.0001) {
                return formatSmallNumber(number);
            } else {
                options.maximumFractionDigits = 7;
                options.minimumFractionDigits = 2;
            }

            return number.toLocaleString(undefined, options);
        }

        //if (number > 1) {
        if (number) {
            const options = {
                minimumFractionDigits: minPrecision,
                maximumFractionDigits: maxPrecision,
            };
            return number.toLocaleString(undefined, options) + text;
        } else {
            return number.toPrecision(2) + text;
        }
    } catch (e) {
        return number;
    }
};

export const amount2Decimal = (amount: BigNumber, token: TokenInfoFormatted): number | undefined => {
    if (!token || !token.symbol) {
        return undefined;
    }
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(token.decimal)).toNumber();
};

function formatSmallNumber(number: number): string {
    const decimalDigits = sumDecimalDigits(number);
    const restNumber = convertDecimalToInteger(number);
    return `0.0{${decimalDigits}}${restNumber}`;
}

function sumDecimalDigits(number: number): number {
    const bignumber = new BigNumber(number);
    const formattedNumber = bignumber.toFixed();
    const result = formattedNumber.toString();
    return countLeadingZeros(result);
}

function countLeadingZeros(number: number | string): number {
    const decimalString = number.toString().split('.')[1] || '';
    let count = 0;
    for (let i = 0; i < decimalString.length; i++) {
        if (decimalString[i] !== '0') {
            break;
        }
        count++;
    }
    return count;
}

const convertDecimalToInteger = (value: number): string => {
    const stringValue = value.toString();
    const integerString = stringValue.replace('.', '').replace(/0+$/, '');
    const integerValue = Number.parseInt(integerString, 10);
    return integerValue.toString().substring(0, 4);
};

function formatBigNumber(number: number): string {
    let formattedNumber = '';
    if (number >= 1e12) {
        formattedNumber = (number / 1e12).toFixed(3) + ' T';
    } else if (number >= 999999999.5) {
        // >1000000000
        formattedNumber = (number / 1000000000).toFixed(3) + ' B';
    } else if (number >= 999999.5) {
        // >1000000 (Prevent rounding to 1000k)
        formattedNumber = (number / 1000000).toFixed(3) + ' M';
    } else if (number >= 999.5) {
        // >1000
        formattedNumber = (number / 1000).toFixed(3) + ' K';
    }
    return formattedNumber.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}
